<template>
  <div class="studentList">
    <div class="topBar">
      <van-nav-bar
        title="学生列表"
        left-text="返回"
        right-text="添加学生"
        left-arrow
        @click-left="onClickLeft"
        @click="onClickRight"
      />
    </div>

    <div class="in content">
      <van-tabs v-model:active="active" @change="changeClass" sticky>
        <van-tab
          v-for="(item, index) in classList"
          :key="item.id"
          :title="item.gradeName + item.className"
        >
          <p>
            <span style="color: rgb(112, 181, 3); margin-right: 20px"
              >我是本班的{{
                classList[index].subjectList.length
                  ? classList[index].subjectList[0].subjectName
                  : ""
              }}老师</span
            >
            <span>本班共有{{ studentList.length }}人</span>
          </p>
          <van-search
            v-model="searchValue"
            show-action
            placeholder="请输入学生姓名"
            autocomplete="off"
            @search="searchStudent"
            @clear="clearSearch"
            @update:model-value="changeValue"
          >
            <template #action>
              <div @click="searchStudent">搜索</div>
            </template>
          </van-search>

          <div class="grid">
            <van-grid :gutter="10" :column-num="column">
              <van-grid-item v-for="item in studentList">
                <div class="item">
                  <div>
                    <div>
                      <van-image
                        width="40px"
                        height="40px"
                        :src="
                          item.sex == '0'
                            ? require('@/assets/images/boy.png')
                            : require('@/assets/images/girl.png')
                        "
                      />
                      <div>{{ item.studentName }}</div>
                    </div>
                    <div>
                      <van-image
                        width="20px"
                        height="20px"
                        :src="require('@/assets/images/case.png')"
                        @click="clickEditor(item)"
                      />
                    </div>
                  </div>

                  <div style="border-bottom: 1px solid #eee">
                    <p>性别：{{ item.sex == "0" ? "男" : "女" }}</p>
                    <p>学号：{{ item.studentsNo }}</p>
                    <p>学年：{{ item.academicYear }}</p>
                  </div>
                </div>
                <p
                  style="color: rgb(112, 181, 3); margin: 10px auto 0 auto"
                  @click="resetPwd(item.id)"
                >
                  重置密码
                </p>
              </van-grid-item>
            </van-grid>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup v-model:show="showSetFrom">
      <h3 style="text-align: center">编辑学生的信息</h3>
      <van-form>
        <van-cell-group inset>
          <van-field
            label="账号"
            autocomplete="off"
            v-model="studentInfo.studentsNo"
            v-if="isAddStudent"
          />
          <van-field
            label="姓名"
            autocomplete="off"
            v-model="studentInfo.studentName"
          />
          <van-field label="性别">
            <template #input>
              <van-radio-group v-model="studentInfo.sex" direction="horizontal">
                <van-radio name="0">男</van-radio>
                <van-radio name="1">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <!-- <van-field
            label="学年"
            autocomplete="off"
            v-model="studentInfo.academicYear"
            v-if="isAddStudent"
          />
          <van-field
            label="年级"
            autocomplete="off"
            v-model="studentInfo.gradeName"
            v-if="isAddStudent"
          /> -->
        </van-cell-group>
        <div class="fnBtn">
          <van-button
            type="warning"
            size="small"
            @click="showSetFrom = false"
            round
            >返回</van-button
          >
          <van-button type="primary" size="small" round @click="savaStudentInfo"
            >保存</van-button
          >
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { Dialog, Toast } from "vant";
import {
  apiGetTeacherBindClass,
  apiGetClassManageStudentList,
  apiSearchStudent,
  apiResetStudentPassword,
  apiSaveStudent,
} from "@/service/teacher1.4.js";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
const route = useRoute();

onBeforeMount(async () => {
  await getTabItem();
  await getStudentList();
});

let active = ref(+route.query.current);
let classList = ref([]);
const getTabItem = async () => {
  let res = await apiGetTeacherBindClass({
    gradeId: userInfo.bindData.grade_id,
    academicYear: userInfo.now_academicYear,
  });
  if (res) {
    classList.value = res.data;
  }
};

// 切换班级tab
const changeClass = async () => {
  await getStudentList();
};

// 获取学生列表
let studentList = ref([]);
const getStudentList = async () => {
  let res = await apiGetClassManageStudentList({
    classId: classList.value[active.value].classID,
    gradeId: classList.value[active.value].gradeID,
    academicYear: classList.value[active.value].academicYear,
  });
  if (res) {
    studentList.value = res.data;
  }
};

// 修改学生信息
let showSetFrom = ref(false);
let studentInfo = ref({});
let isAddStudent = ref(false);
const clickEditor = (value, isAdd) => {
  if (!isAdd) {
    showSetFrom.value = true;
    isAddStudent.value = false;
    // 深拷贝
    studentInfo.value = JSON.parse(JSON.stringify(value));
  }
};
const savaStudentInfo = async () => {
  let formDate = new FormData();
  formDate.append("id", studentInfo.value.id);
  formDate.append("studentsNo", studentInfo.value.studentsNo);
  formDate.append("studentName", studentInfo.value.studentName);
  formDate.append(
    "gradeID",
    studentInfo.value.gradeID
      ? studentInfo.value.gradeID
      : classList.value[active.value].gradeID
  );
  formDate.append(
    "classID",
    studentInfo.value.classID
      ? studentInfo.value.classID
      : classList.value[active.value].classID
  );
  formDate.append("sex", studentInfo.value.sex);

  let res = await apiSaveStudent(formDate);
  if (res) {
    Toast.success("修改成功");
    showSetFrom.value = false;
    await getStudentList();
  }
};

// 添加学生
const onClickRight = async () => {
  showSetFrom.value = true;
  isAddStudent.value = true;
  // 初始化学生信息对象
  // studentInfo.value.academicYear = "";
  studentInfo.value.id = "";
  studentInfo.value.studentsNo = "";
  studentInfo.value.studentName = "";
  studentInfo.value.gradeID = "";
  studentInfo.value.classID = "";
  studentInfo.value.sex = "";
};

// 搜索
let searchValue = ref("");
const searchStudent = async () => {
  let res = await apiSearchStudent({
    gradeId: classList.value[active.value].gradeID,
    classId: classList.value[active.value].classID,
    searchName: searchValue.value,
  });
  if (res) {
    studentList.value = res.data;
  }
};
const clearSearch = async () => {
  await getStudentList();
};
const changeValue = async () => {
  if (!searchValue.value) {
    await getStudentList();
  }
};

let column = ref(4);
// 屏幕缩放触发的方法
window.onresize = function (e) {
  if (document.body.clientWidth < 580) {
    column.value = 3;
  } else {
    column.value = 4;
  }
};

const resetPwd = (id) => {
  Dialog.confirm({
    title: "重置密码",
    message: "确定为张三重置密码吗？",
  })
    .then(async () => {
      let formDate = new FormData();
      formDate.append("studentIds", id);
      let res = await apiResetStudentPassword(formDate);
      if (res) {
        Toast.success("重置成功");
      }
    })
    .catch(() => {
      // on cancel
    });
};

const onClickLeft = () => history.back();
</script>

<style lang="less" scoped>
@import "@/common/style/adapting.less";
.topBar {
  width: 100%;
  position: fixed;
  top: 0;
}
.content {
  margin-top: 40px;
}
.grid {
  .item {
    width: 100%;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

:deep(.van-tab__text) {
  font-size: 10px;
}

:deep(.van-grid-item) {
  border: none;
}
:deep(.van-popup--center) {
  border-radius: 10px;
}
:deep(.van-form) {
  width: 65vw;
  padding: 10px;
}
.fnBtn {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  .van-button {
    width: 40%;
  }
}
@media screen and (orientation: portrait) and (max-device-width: 420px) {
  :deep(.van-form) {
    width: 90vw;
  }
}
</style>
